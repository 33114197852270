<template>
	<div class="page">
		<div class="bannerBox">
			<p>{{ lang == 6 ? '保时捷控股车队' : 'Team Porsche Holding ' }}</p>
		</div>
		<div class="swiperBox">
			<div class="left">
				<div class="left-content">
					<p class="title">{{ info.title }}</p>
					<p>{{ info.subtitle }}<br></p>
					<p class="left-content-describe" v-html="info.describe"></p>
				</div>
			</div>
			<div class="right" v-for="(item, index) in memberList" :key="index">
				<div class="imgBox">
					<img :src="item.image" mode="widthFit" class="swiperImg" />
				</div>
				<div class="textBox">
					<div class="title">{{ item.title }}</div>
					<div class="text" v-html="item.describe"></div>
				</div>
			</div>
		</div>
		<div class="mode2">
			<div class="title">
				<div class="titTop">{{ scheduleInfo.title }}</div>
				<!-- <div class="titBottom">{{lang==6?'比赛赛程':'schedule'}}</div> -->
			</div>
			<div class="mode2-content">
				<div class="map">
					<img :src="scheduleInfo.image" alt="">
				</div>
				<!-- <img src="../assets/porsche/map.png" /> -->
				<div class="list">
					<div class="line">
						<span>{{ lang == 6 ? '赛道信息' : 'Circuit, Country' }}</span>
						<span>{{ lang == 6 ? '回合' : 'Round' }}</span>
						<span>{{ lang == 6 ? '日期' : 'Date' }}</span>
					</div>
					<div class="line" v-for="(item, index) in scheduleList" :key="index">
						<span>{{ item.title }}</span>
						<span>{{ item.round }}</span>
						<span>{{ item.start_time }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="mode3">
			<div class="title-flexBox">
				<div class="titleBox">
					<div class="titTop">{{ lang == 6 ? '比赛信息' : 'Competition information' }}</div>
					<!-- <div class="titBottom">{{lang==6?'比赛信息':'Game information'}}</div> -->
				</div>
				<!-- <selectBox @change="page=1;getActivityList($event)"></selectBox> -->
			</div>
			<div class="mode3-list">
				<div class="mode3-list-item" v-for="(item, index) in activityList" :key="index">
					<div class="mode3-list-item-text">
						<div>
							<p class="title">{{ item.title }}</p>
							<p class="mode3-list-item-text-describe" v-html="item.describe"></p>
						</div>
						<p class="time">
							<i class="el-icon-time"></i>
							<span>{{ item.business_time }}</span>
						</p>
					</div>
					<div class="mode3-right">
						<img src="../assets/arrows1.png" :style="item.swiperCur == 0 ? 'opacity: .3' : ''"
							class="arrows1" @click="swiperPrev(index)" />
						<img :style="item.swiperCur == item.atlas.length - 1 ? 'opacity: .3' : ''"
							src="../assets/arrows1.png" class="arrows1" @click="swiperNext(index)" />
						<!-- <viewer :images="item.atlas.split('|')"> -->
						<swiper :options="swiperOption1" :ref="'mode3Swper' + index"
							@slide-change-transition-end="handleClickSlide(index)">
							<swiper-slide class="swiper-slide" v-if="item.video">
								<video controls :src="item.video" v-if="item.video" :poster="item.image"></video>
							</swiper-slide>
							<swiper-slide class="swiper-slide" v-for="(img, index) in item.atlas" :key="index">
								<!-- <img :src="img" mode="widthFit" class="swpierImg" /> -->
								<el-image :src="img" mode="widthFit" class="swpierImg"
									:preview-src-list="item.atlas"></el-image>
							</swiper-slide>
						</swiper>

						<!-- </viewer> -->
					</div>
				</div>
				<el-pagination background layout="prev, pager, next" :page-size="limit" :total="total"
					:current-page="page" @current-change="changePage">
				</el-pagination>
			</div>
		</div>
		<!-- <div class="mode5">
			<div class="titleBox">
				<div class="titTop">Past activities</div>
				<div class="titBottom">{{lang==6?'往期活动':'Past activities'}}</div>
			</div>
			<div class="mode5Content">
				<swiper :options="swiperOption2" ref="mySwiper2" class="activitiesSwiper">
					<swiper-slide class="swiper-slide" v-for="(item,index) in previousList" :key="index">
						<a class="swiperItem" :href="item.dump_link" target="_blank">{{item.title}}</a>
					</swiper-slide>
				</swiper>
				<div class="btnBox">
					<span @click="$refs.mySwiper2.swiper.slidePrev()"> < </span>
					<span @click="$refs.mySwiper2.swiper.slideNext()"> > </span>
				</div>
			</div>
		</div> -->
		<img :src="info.background" class="mode4" />
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js";
	import 'swiper/dist/css/swiper.css';
	import selectBox from '../components/selectBox.vue'
	import mixinLang from '../components/mixin.js'
	var mapIcon = require('../assets/mapIcon.png')
	var mapbg = require('../assets/mapbg.png')
	export default {
		mixins: [mixinLang],
		components: {
			swiper,
			swiperSlide,
			selectBox
		},
		data() {
			return {
				page: 1,
				limit: 10,
				total: 0,
				info: {},
				scheduleInfo: {
					image: '',
					title: ''
				},
				activityList: [],
				scheduleList: [],
				memberList: [],
				previousList: [],
				// 轮播配置
				swiperOption2: {
					slidesPerView: 9,
					loop: false,
				},
				swiperOption1: {
					slidesPerView: 1,
					loop: false,
				},
				map: ''
			}
		},
		methods: {
			handleClickSlide(index) {
				this.activityList[index].swiperCur = this.$refs['mode3Swper' + index][0].swiper.realIndex
			},
			swiperPrev(index) {
				var that = this
				that.$refs['mode3Swper' + index][0].swiper.slidePrev()
			},
			swiperNext(index) {
				var that = this
				that.$refs['mode3Swper' + index][0].swiper.slideNext()
			},
			// 改变分页
			changePage() {
				this.page = page
				this.getActivityList()
			},
			getInfo() {
				// 获取基本信息
				this.$api.teamGetIntroduction().then(res => {
					this.info = res.data
				})
				this.$api.getConfigure({
					type: 20
				}).then(res => {
					this.scheduleInfo = res.data
					console.log(res.data)
					// console.log(this.scheduleInfo)
				})
				this.$api.getMemberList({
					cate_no: 'jm879875'
				}, {
					page: 1,
					limit: 100
				}).then(res => {
					this.memberList = res.list
				})
				// 获取赛程
				this.$api.getScheduleList({
					cate_no: 'ed321243'
				}, {
					page: 1,
					limit: 100
				}).then(res => {
					this.scheduleList = res.list
				})
				// 获取往期活动
				/* this.$api.getPreviousList({
				  cate_no: 'wcvt5ev54'
				},{
				  page:1,
				  limit: 1000
				}).then(res=>{
				  this.previousList = res.list
				}) */
				this.getActivityList()
			},
			// 获取保时捷活动列表
			getActivityList(e = {}) {
				this.$api.getActivityList({
					cate_no: '64bbnn54',
					form: 'all',
					city_id: e.c,
					business_time: e.d ? e.d : ''
				}, {
					page: this.page,
					limit: this.limit
				}).then(res => {
					// this.activityList = res.list
					// this.total = Number(res.paginated.count)

					this.$nextTick(() => {
						var list = res.list
						list.forEach(item => {
							item.atlas = item.atlas.split('|')
							item.swiperCur = 0
						})
						this.activityList = list
						this.total = Number(res.paginated.count)
					})


				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		background-color: #FFF;
		padding: 0;
	}

	.bannerBox {
		width: 100%;
		height: 481px;
		background: url(../assets/porsche/banner.png) no-repeat top/100% 100%;
		position: relative;

		p {
			position: absolute;
			top: 251px;
			left: 115px;
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 64px;
			font-weight: bold;
			color: #FFF;
		}
	}

	.swiperBox {
		display: flex;
		color: #FFF;
		background-color: #101621;
		height: 686px;
		padding: 0px 120px;
		justify-content: space-between;
		align-items: center;

		.left {
			width: 543px;
			max-height: 512px;
			background: #101621;
			position: relative;
			overflow: hidden;
			padding-right: 20px;

			/deep/.text p,
			/deep/.text span {
				line-height: 40px;
				font-size: 24px;
			}

			.left-content {
				.btnBox {
					cursor: pointer;
					display: flex;
					width: 150px;
					justify-content: space-between;
					margin-top: 100px;

					img {
						width: 64px;
						height: 64px;
					}

					.left {
						transform: rotate(-180deg);
					}
				}
			}

			.left-content-describe {
				height: 380px;
				overflow: hidden;
				overflow-y: scroll;
			}

			/deep/ p {
				font-family: SourceHanSansCN-Normal, sans-serif;
				font-size: 24px;
				font-weight: 350;
				// white-space: pre-wrap;
			}

			.title {
				font-family: SourceHanSansCN-Medium, sans-serif;
				font-size: 42px;
				font-weight: 500;
				display: inline-block;
				padding-right: 20px;
				border-right: 5px solid #97233F;
				line-height: 60px;
				margin-bottom: 10px;
			}

			p:nth-child(2) {
				margin-bottom: 20px;
			}
		}

		.right {
			display: flex;
			width: 1050px;
			height: 440px;
			border-radius: 20px;
			background: linear-gradient(90deg, #202938 0%, #202938 100%);
			border: 1px solid #B5A191;
			justify-content: space-around;

			.textBox {
				width: 580px;
				height: 398px;
				border-radius: 8px;
				padding: 30px 0px 0;
				overflow-y: scroll;
				margin-top: 15px;

				/deep/ .text {
					font-family: SourceHanSansCN-Normal, sans-serif;
					font-size: 16px;
					font-weight: 350;
					line-height: 30px;
					color: rgba(255, 255, 255, 0.75);
				}

				.title {
					font-family: SourceHanSansCN-Regular, sans-serif;
					font-size: 28px;
					font-weight: normal;
					color: #FFF;
					margin-bottom: 20px;
				}

				/deep/.text p,
				/deep/.text span {
					line-height: 40px;
					font-size: 24px;
				}
			}

			img {
				width: 384.45px;
				height: 499.55px;
				border-radius: 8px;
				transform: translateY(-30px);
			}
		}
	}

	.mode2 {
		padding: 111px;
		width: 100%;
		background-color: #FFF;

		.mode2-content {
			display: flex;
			margin-top: 70px;
			justify-content: space-between;

			.map {
				img {
					width: 609px;
				}
			}

			.list {
				width: 900px;
				font-family: SourceHanSansCN-Regular, sans-serif;
				font-size: 20px;
				font-weight: 350;
				color: #333333;
				max-height: 560px;
				overflow-y: scroll;
				padding-right: 20px;

				.line {
					display: flex;
					align-items: center;
					border-bottom: 1px solid #AAAAAA;
					padding: 12px 0;
					line-height: 30px;

					&:first-child {
						border-bottom-color: #97233F;
					}

					&:last-child {
						border-bottom-color: #97233F;
					}

					span {
						flex: 1;
						text-align: center;

						&:nth-child(1) {
							flex: 1.5;
							// text-align: left;
						}
					}

					&:nth-child(1) {
						color: #97233F;
						font-family: SourceHanSansCN-Bold, sans-serif;
						font-size: 24px;
						font-weight: bold;
					}
				}
			}
		}
	}

	.mode3 {
		padding: 111px 0 0;

		.title-flexBox {
			padding: 0 111px;
			display: flex;
			justify-content: space-between;
			margin-bottom: 50px;
		}

		.mode3-list {
			.mode3-list-item {
				display: flex;
				justify-content: space-between;
				padding: 76px 111px;
				box-sizing: content-box;
				height: 380px;

				&:nth-child(odd) {
					background-color: #F2F2F2;
				}

				&:nth-child(even) {
					background-color: #FFF;
				}

				.mode3-list-item-text {
					width: calc(100% - 700px);
					font-family: SourceHanSansCN-Regular, sans-serif;
					font-size: 18px;
					font-weight: 350;
					line-height: 40px;
					letter-spacing: 0em;
					color: #787878;
					height: 380px;

					.title {
						margin-bottom: 10px;
					}

					/deep/ {
						font-size: 24px;
						line-height: 40px;
					}

					>div {
						position: relative;
						// height: 340px;
						padding-right: 20px;
						overflow: hidden;

						.mode3-list-item-text-describe {
							// overflow: hidden;
							overflow-y: scroll;
							height: 300px;
						}
					}

					&:last-child {
						display: flex;
						align-items: center;
						position: absolute;
						bottom: 0;

						i {
							margin-right: 20px;
						}
					}
				}

				.title {
					font-family: SourceHanSansCN-Bold, sans-serif;
					font-size: 26px;
					font-weight: bold;
					color: #97233F;
				}
			}

			.mode3-right {
				// width: 1070px;
				display: flex;
				justify-content: flex-start;
				position: relative;

				/* 	&::after {
					content: '';
					display: block;
					position: absolute;
					width: 200px;
					height: 300px;
					top: -10px;
					left: -10px;
					background-color: #97233F;
				} */

				/deep/ .swiper-container {
					width: 640px;
					height: 360px;
					margin: 0;
					margin-right: 20px;
				}

				.swpierImg {
					width: 640px;
					height: 360px;
				}

				/deep/ .swiper-pagination-bullet-active {
					background-color: #97233F;
				}

				video {
					width: 640px;
					height: 360px;
					background-color: #151516;
				}
			}

			.arrows1 {
				width: 56px;
				height: 56px;
				position: absolute;
				left: -60px;
				z-index: 9;
				top: calc(50% - (56px/2));

				&:nth-child(2) {
					left: auto;
					right: -50px;
					transform: rotate(180deg);
				}
			}
		}
	}

	.mode4 {
		width: 100%;
		vertical-align: middle;
	}

	.mode5 {
		.titleBox {
			padding: 0 111px;
		}

		.mode5Content {
			position: relative;
			margin: 50px 0 80px;

			&::after {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background-color: #97233F;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}

			.activitiesSwiper {
				width: 1700px;
				margin: 0 auto;

				/deep/ .swiper-wrapper {
					justify-content: center;
				}

				/deep/.swiper-slide {
					text-align: center;
				}

				.swiperItem {
					// width: 120px;
					padding: 0 20px;
					height: 48px;
					display: inline-block;
					line-height: 48px;
					background: #FFFFFF;
					box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
					font-family: SourceHanSansCN-Medium, sans-serif;
					font-size: 24px;
					font-weight: 500;
					margin: 20px 0;
					color: #000;
					text-decoration: none;
					cursor: pointer;
				}
			}

			.btnBox {
				span {
					position: absolute;
					width: 48px;
					height: 48px;
					border-radius: 8px;
					background: linear-gradient(0deg, #97233F, #97233F), #FFFFFF;
					box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
					color: #FFF;
					text-align: center;
					line-height: 48px;
					top: 20px;
					left: 40px;
					cursor: pointer;
					z-index: 99;

					&:nth-child(2) {
						left: inherit;
						right: 40px;
					}
				}

			}
		}
	}

	.el-pagination {
		text-align: center;
		margin: 100px 0;

		/deep/ .active {
			background-color: #97233F !important;
		}
	}

	/deep/ .BMapLabel {
		color: #FFF;
		border: 0 !important;
		fontSize: 14px;
		padding: 7px !important;
		vertical-align: middle;
		background: url(../assets/mapbg.png) no-repeat top/100% 100% !important;
	}
</style>